//vesting contract addresses
const addresses = {
  lpi: "0x64AF31bD9dEA62977DCd19b627eCdDF043A5ebc4",
  shoefy: "0x36F92dCde0E23D2C59C9e9678B815F77386C6250",
  satoshiCity: "0x084380a2562F3239e906E4bC719149E07EA8aFDa",
  doragon: "0x18831dF514A34ae56972693d70A3f10F04c07160",
  duckie: "0xCB3B75304993ce36EaF0C45e5D48c4A15945Cb0A",
  boldPoint: "0xD5403AB945E77c32B260c4Bfed18b7e4687E9E88",
  synergyLand: "0x56A0EC5428A2d064964EAAaaB080e92F9Ba1f320",
  qorpo1: "0xbEF94899d7D09724b7509692c35d3442E47f2D19",
  qorpo2: "0x303a6a91a299a700f1d7cbb7cc831b1270bad4de",
  qorpo3: "0x3E4fbD8b95CBdc95F73629252C37C33C788a73b0",
  qorpo4: "0x885b81fF2cf497d06ec764f841a1a3a3E0C6FdC3",
  qorpo5: "0x8077cc93C5EE15aA1D7DBDfbAe36311Daeb457B1",
  qorpo6: "0x719bbb24BBb1f3B04bD6BB5DfC91a17277167778",
  unbound: "0x3ca3Db7750aF163Fb9d475a251AD9c6eD4aFA2e3",
  lyber: "0xa618Dc2756A877AF1830EC2416EC7f7237EF9631",
  litcraft: "0x8fcB42D6388A9ADbEB09F6576a14138a9D87b6E7",
  // ADD MORE
};

export default addresses;
