import React, {useEffect} from "react";

import {NavLink} from "react-router-dom";
import {toast} from "react-toastify";
import {useWallet} from "use-wallet";

import "../App.css";
import "@fontsource/roboto";
import "react-toastify/dist/ReactToastify.css";
import {getChainName} from "../components/constants";

import BackArrow from "../assets/back-arrow.png";
import {useWalletInfoAirdrop} from "../hooks/useWalletInfoAirdrop";
import useTransact from "../hooks/useTransact";

function ClaimAirdrop({ tokenData, openModal }) {
  const wallet = useWallet();
  const { name, symbol, data, chainId } = tokenData;

  const {
    setLoading,
    readWalletData,
    clearWalletData,
    total,
    bin,
    isClaimed,
    loading
  } = useWalletInfoAirdrop(data[0]);

  const [claim] = useTransact();

  const Loader = (
    <span className="loader1">
      {" "}
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </span>
  );

  const chainName = getChainName(chainId);

  useEffect(() => {
    if (wallet.account) {
      // readWalletData(wallet.account);
      setLoading(true);
      // console.log(wallet.connector);
      readWalletData(wallet.account);

      const intervalID = setInterval(() => {
        console.log("reading");
        readWalletData(wallet.account);
      }, 30000);

      return () => clearInterval(intervalID);
    }

    if (!wallet.account) {
      clearWalletData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wallet.account]);

  useEffect(() => {
    if (wallet.status === "connected" && !wallet.account) {
      wallet.reset();
    }
  }, [wallet.status, wallet.account, wallet]);

  useEffect(() => {
    console.log(wallet.chainId, "chainID");
    console.log(wallet.account, "account");
    console.log(wallet.status, "status");
    console.log(chainId, "chain");

    if (wallet.chainId !== chainId && wallet.account) {
      toast.error(
        `Unsupported network ${wallet.chainId}. Please Connect to ${chainName}`
      );

      wallet.reset();
      setLoading(false);
    }

    if (wallet.error) {
      toast.error(`${wallet.error}`);
    } else if (wallet.status === "connected") {
      toast(`Connected`);
    } else if (wallet.status === "disconnected") {
      toast(`Disconnected`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wallet.status, wallet.error, wallet.chainId]);

  return (
    <div className="main-box">
      <NavLink to="/">
        <div
          style={{
            marginRight: "auto",
            display: "flex",
            alignItems: "center",
            textDecoration: "none"
          }}
        >
          <img
            src={BackArrow}
            alt="back arrow"
            style={{ width: "30px", height: "30px" }}
          />
          {"  "}
          Back
        </div>
      </NavLink>

      <h1>{name} VESTING DISTRIBUTION</h1>
      <div className="overlay">
        <p>
          Claim Round {tokenData.airdropRound}:{" "}
          {wallet.account && !loading
            ? new Date(tokenData.startDate).toUTCString()
            : ""}
          {loading ? Loader : ""}{" "}
        </p>

        <div className="box-container">
          <div className="smallbox">
            <div className="container2">
              <div className="progressbar-container2">
                <div
                  className="progressbar-complete2"
                  style={{
                    width: `${isClaimed ? 100 : 0}%`
                  }}
                >
                  <div className="progressbar-liquid2"></div>
                </div>
              </div>
              <p>
                Available: {total.toFixed(2)} {symbol}
              </p>
            </div>
          </div>
        </div>
        <div className="claim-box">
          {wallet.account && !loading && total > 0 && !isClaimed && bin && (
            <button
              style={{ width: "fit-content" }}
              onClick={() => {
                claim(data[0].contract, bin);
              }}
            >
              Claim {total.toFixed(2)} {symbol}
            </button>
          )}
          {wallet.account && !loading && isClaimed && (
            <button>Already Claimed</button>
          )}
          {!wallet.account && (
            <button onClick={openModal} variant="primary">
              Connect Wallet
            </button>
          )}
          {wallet.account && loading && <button>Loading ...</button>}
        </div>
      </div>
    </div>
  );
}

export default ClaimAirdrop;
