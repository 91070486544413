import {Contract} from "@ethersproject/contracts";
import {ethers} from "ethers";

import {abis, addresses, merkletree} from "../contracts";

/*
 * To ADD a new chain,
 * 1. add it to the `CHAINS` object above.
 * 2. add it to the `CHAIN_CHAINID` object below.
 * 3. add it to the index.js
 */

/*
 * To ADD a new contract
 * 1. Add to addresses.js
 * 2. Add to contract below
 * 3. Add to data below
 * 4. Import and create route on App
 * 5. Import and Add to ProjectList
 */

/* chain configs */
const CHAINS = {
  1: {
    rpcUrl: "https://eth.llamarpc.com/",
    name: "ETH",
    explorerLink: "https://etherscan.io/tx/",
    color: "grey",
  },
  56: {
    rpcUrl: "https://bsc-dataseed.binance.org/",
    name: "BSC",
    explorerLink: "https://bscscan.com/tx/",
    color: "gold",
  },
  43114: {
    rpcUrl: "https://api.avax.network/ext/bc/C/rpc",
    name: "AVAX",
    explorerLink: "https://snowtrace.io/tx/",
    color: "red",
  },
  137: {
    rpcUrl: "https://polygon.meowrpc.com",
    name: "POLYGON",
    explorerLink: "https://polygonscan.com/tx/",
    color: "purple",
  },
};

const CHAIN_CHAINID = {
  eth: 1,
  bsc: 56,
  avax: 43114,
  polygon: 137,
};

export function getRpcUrl(chainId) {
  const chain = CHAINS[chainId];
  return chain ? chain.rpcUrl : null;
}

export function getRpcProvider(chainId) {
  const chain = CHAINS[chainId];
  return chain ? new ethers.providers.JsonRpcProvider(chain.rpcUrl) : null;
}

export function getChainName(chainId) {
  const chain = CHAINS[chainId];
  return chain ? chain.name : "Unsupported Network";
}

export function getExplorerLink(chainId) {
  const chain = CHAINS[chainId];
  return chain ? chain.explorerLink : null;
}

export function getChainColer(chainId) {
  const chain = CHAINS[chainId];
  return chain ? chain.color : "";
}

/* contracts */
const lpiContract = new Contract(
  addresses.lpi,
  abis.claim,
  getRpcProvider(CHAIN_CHAINID.bsc),
);
const shoefyContract = new Contract(
  addresses.shoefy,
  abis.claim,
  getRpcProvider(CHAIN_CHAINID.bsc),
);
const satoshiCityContract = new Contract(
  addresses.satoshiCity,
  abis.claim,
  getRpcProvider(CHAIN_CHAINID.bsc),
);
const doragonContract = new Contract(
  addresses.doragon,
  abis.claim,
  getRpcProvider(CHAIN_CHAINID.bsc),
);
const duckieContract = new Contract(
  addresses.duckie,
  abis.claim,
  getRpcProvider(CHAIN_CHAINID.bsc),
);
const boldPointContract = new Contract(
  addresses.boldPoint,
  abis.claim,
  getRpcProvider(CHAIN_CHAINID.avax),
);
const synergyLandContract = new Contract(
  addresses.synergyLand,
  abis.claim,
  getRpcProvider(CHAIN_CHAINID.polygon),
);
const unboundContract = new Contract(
  addresses.unbound,
  abis.claim,
  getRpcProvider(CHAIN_CHAINID.polygon),
);
const qorpo1Contract = new Contract(
  addresses.qorpo1,
  abis.merkle,
  getRpcProvider(CHAIN_CHAINID.eth),
);
const qorpo2Contract = new Contract(
    addresses.qorpo2,
    abis.merkle,
    getRpcProvider(CHAIN_CHAINID.eth),
);
const qorpo3Contract = new Contract(
    addresses.qorpo3,
    abis.merkle,
    getRpcProvider(CHAIN_CHAINID.eth),
);
const qorpo4Contract = new Contract(
    addresses.qorpo4,
    abis.merkle,
    getRpcProvider(CHAIN_CHAINID.eth),
);
const qorpo5Contract = new Contract(
    addresses.qorpo5,
    abis.merkle,
    getRpcProvider(CHAIN_CHAINID.eth),
);
const qorpo6Contract = new Contract(
    addresses.qorpo6,
    abis.merkle,
    getRpcProvider(CHAIN_CHAINID.eth),
);
const lyberContract = new Contract(
  addresses.lyber,
  abis.claim,
  getRpcProvider(CHAIN_CHAINID.eth),
);
const litcraftContract = new Contract(
  addresses.litcraft,
  abis.claim,
  getRpcProvider(CHAIN_CHAINID.eth),
);

// ADD MORE

/* token data */
export const lpiData = {
  name: "LPI DAO",
  symbol: "$LPI",
  contract: lpiContract,
  chainId: CHAIN_CHAINID.bsc,
};

export const shoefyData = {
  name: "SHOEFY",
  symbol: "$SHOE",
  contract: shoefyContract,
  chainId: CHAIN_CHAINID.bsc,
};

export const satoshiCityData = {
  name: "SATOSHI CITY",
  symbol: "$CITY",
  contract: satoshiCityContract,
  chainId: CHAIN_CHAINID.bsc,
};

export const doragonData = {
  name: "DORAGON LAND",
  symbol: "$DOR",
  contract: doragonContract,
  chainId: CHAIN_CHAINID.bsc,
};

export const duckieData = {
  name: "DUCKIE LAND",
  symbol: "$MMETA",
  contract: duckieContract,
  chainId: CHAIN_CHAINID.bsc,
};

export const boldPointData = {
  name: "BOLD POINT",
  symbol: "$BPT",
  contract: boldPointContract,
  chainId: CHAIN_CHAINID.avax,
};

export const synergyLandData = {
  name: "SYNERGY LAND",
  symbol: "$SNG",
  contract: synergyLandContract,
  chainId: CHAIN_CHAINID.polygon,
};

export const unboundData = {
  name: "UNBOUND",
  symbol: "$UN",
  contract: unboundContract,
  chainId: CHAIN_CHAINID.polygon,
};

export const qorpoData = {
  name: "QORPO Token",
  symbol: "$QORPO",
  data: [
    {
      merkle: merkletree.qorpo1,
      contract: qorpo1Contract,
    },
  ],
  chainId: CHAIN_CHAINID.eth,
  airdropRound: 1,
  startDate: 1743378877000
};

export const qorpo2Data = {
  name: "QORPO Token",
  symbol: "$QORPO",
  data: [
    {
      merkle: merkletree.qorpo2,
      contract: qorpo2Contract,
    },
  ],
  chainId: CHAIN_CHAINID.eth,
  airdropRound: 2,
  startDate: 1743372000000
};

export const qorpo3Data = {
  name: "QORPO Token",
  symbol: "$QORPO",
  data: [
    {
      merkle: merkletree.qorpo3,
      contract: qorpo3Contract,
    },
  ],
  chainId: CHAIN_CHAINID.eth,
  airdropRound: 3,
  startDate: 1772323200000
};

export const qorpo4Data = {
  name: "QORPO Token",
  symbol: "$QORPO",
  data: [
    {
      merkle: merkletree.qorpo4,
      contract: qorpo4Contract,
    },
  ],
  chainId: CHAIN_CHAINID.eth,
  airdropRound: 4,
  startDate: 1803859200000
};

export const qorpo5Data = {
  name: "QORPO Token",
  symbol: "$QORPO",
  data: [
    {
      merkle: merkletree.qorpo5,
      contract: qorpo5Contract,
    },
  ],
  chainId: CHAIN_CHAINID.eth,
  airdropRound: 5,
  startDate: 1835481600000
};

export const qorpo6Data = {
  name: "QORPO Token",
  symbol: "$QORPO",
  data: [
    {
      merkle: merkletree.qorpo6,
      contract: qorpo6Contract,
    },
  ],
  chainId: CHAIN_CHAINID.eth,
  airdropRound: 6,
  startDate: 1867017600000
};

export const lyberData = {
  name: "LYBER",
  symbol: "LYB",
  contract: lyberContract,
  chainId: CHAIN_CHAINID.eth,
};

export const liftcraftData = {
  name: "Litcraft",
  symbol: "FIAS",
  contract: litcraftContract,
  chainId: CHAIN_CHAINID.eth,
};

// ADD MORE
