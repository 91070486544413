import qorpo1 from "./merkletree/merkletree-qorpo0324.json";
import qorpo2 from "./merkletree/merkletree-qorpo0325.json";
import qorpo3 from "./merkletree/merkletree-qorpo0326.json";
import qorpo4 from "./merkletree/merkletree-qorpo0327.json";
import qorpo5 from "./merkletree/merkletree-qorpo0328.json";
import qorpo6 from "./merkletree/merkletree-qorpo0329.json";

const merkletree = {
  qorpo1,
  qorpo2,
  qorpo3,
  qorpo4,
  qorpo5,
  qorpo6,
};

export default merkletree;
