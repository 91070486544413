import React, {useEffect, useState} from "react";

import "@fontsource/roboto";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";

import {useWallet} from "use-wallet";
import {ToastContainer} from "react-toastify";
import {Route, Routes} from "react-router-dom";

import Footer from "./components/Footer/Footer.js";
import ConnectWalletModal from "./components/ConnectWalletModal/ConnectWalletModal";
import Vesting from "./views/Vesting";
import Topbar from "./layout/Topbar";
import ProjectList from "./views/ProjectList";
import {
    boldPointData,
    doragonData,
    duckieData,
    liftcraftData,
    lpiData,
    lyberData,
    qorpo2Data,
    qorpoData,
    satoshiCityData,
    shoefyData,
    synergyLandData,
    unboundData,
} from "./components/constants";
import ClaimAirdrop from "./views/ClaimAirdrop";

// ADD MORE

function App() {
  const wallet = useWallet();
  const [modalIsOpen, setIsOpen] = useState(false);

  function closeWalletModal() {
    setIsOpen(false);
  }

  function openWalletModal() {
    setIsOpen(true);
  }

  useEffect(() => {
    if (wallet.status === "connected") {
      closeWalletModal();
    }
  }, [wallet.status]);

  return (
    <div className="App">
      <ToastContainer theme="dark" />
      <Topbar openModal={openWalletModal} />

      <ConnectWalletModal modalIsOpen={modalIsOpen} setIsOpen={setIsOpen} />

      <Routes>
        <Route exact path="/" element={<ProjectList />} />
        <Route
          path="lpi"
          element={<Vesting openModal={openWalletModal} tokenData={lpiData} />}
        />
        <Route
          path="shoe"
          element={
            <Vesting openModal={openWalletModal} tokenData={shoefyData} />
          }
        />
        <Route
          path="satoshicity"
          element={
            <Vesting openModal={openWalletModal} tokenData={satoshiCityData} />
          }
        />
        <Route
          path="doragonland"
          element={
            <Vesting openModal={openWalletModal} tokenData={doragonData} />
          }
        />
        <Route
          path="duckieland"
          element={
            <Vesting openModal={openWalletModal} tokenData={duckieData} />
          }
        />
        <Route
          path="boldpoint"
          element={
            <Vesting openModal={openWalletModal} tokenData={boldPointData} />
          }
        />
        <Route
          path="synergyland"
          element={
            <Vesting openModal={openWalletModal} tokenData={synergyLandData} />
          }
        />
        <Route
          path="qorpo"
          element={
            <>
                {/* Deposit tokens and enable every year */}
                {/*<ClaimAirdrop openModal={openWalletModal} tokenData={qorpo6Data} />*/}
                {/*<ClaimAirdrop openModal={openWalletModal} tokenData={qorpo5Data} />*/}
                {/*<ClaimAirdrop openModal={openWalletModal} tokenData={qorpo4Data} />*/}
                {/*<ClaimAirdrop openModal={openWalletModal} tokenData={qorpo3Data} />*/}

                <ClaimAirdrop openModal={openWalletModal} tokenData={qorpo2Data} />
                <ClaimAirdrop openModal={openWalletModal} tokenData={qorpoData} />
            </>
          }
        />
        <Route
          path="unbound"
          element={
            <Vesting openModal={openWalletModal} tokenData={unboundData} />
          }
        />
        <Route
          path="lyber"
          element={
            <Vesting openModal={openWalletModal} tokenData={lyberData} />
          }
        />
        <Route
          path="litcraft"
          element={
            <Vesting openModal={openWalletModal} tokenData={liftcraftData} />
          }
        />
        {/* ADD MORE */}
      </Routes>

      <Footer />
    </div>
  );
}

export default App;
